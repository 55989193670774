<template>
  <div class="create-or-edit">
    <div>
      <el-form  ref="sendBackForm" :model="sendBackInfo" :rules="rules" label-position="left" label-width="120px" size="small">
        <el-form-item label="送回楼层：" prop="sendFloor">
          <el-select v-model="sendBackInfo.sendFloor" clearable style="width: 220px">
            <el-option v-for="item in needStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重量(KG)：" prop="weight" >
          <el-input v-model="sendBackInfo.weight" style="width: 220px"></el-input>
        </el-form-item>
        <el-form-item label="送回时间：" prop="sendTime">
          <el-date-picker
            v-model="sendBackInfo.sendTime"
            value-format="yyyy-MM-ddTHH:mm:ss.000Z"
            type="datetime"
            style="width: 220px"
            placeholder="选择日期时间"
            align="right">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onsubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {saveSendBackInfo} from '@/services/sendBack'
import {  findAllNeedStatus} from '@/services/status'

export default {
  name: 'Create',
  props: {},
  created() {
    this.loadNeedStatus()
  },
  data() {
    return {
      sendBackInfo: {
      },
      needStatusList: [],
      // 用于设置表单校验规则
      rules: {
        sendFloor: [{required: true,trigger: 'blur',message: '送回楼层不能为空'}],
        weight: [{required: true,trigger: 'blur',message: '重量不能为空'}],
        sendTime: [{required: true,trigger: 'blur',message: '送回时间不能为空'}]
      },
    }
  },
  methods: {
    onCancel() {
      //设置取消状态，让父组件处理
      this.$emit('cancel')
      this.sendBackInfo = {}
    },


   async loadNeedStatus() {
      const {data} = await findAllNeedStatus()
      if (200 === data.code) {
        this.needStatusList = data.data
      } else {
        this.$message.error('查询需方列表失败')
      }
    },


    async onsubmit() {
      // 设置校验
      await this.$refs.sendBackForm.validate()
      const {data} = await saveSendBackInfo(this.sendBackInfo)
      if (200 === data.code) {
        // 关闭提示框(需要子组件向父组件传递状态)
        this.$emit('success')
        // 设置成功提示
        this.$message.success('添加成功')
        // 清除表单内容
        this.sendBackInfo = {}
      } else {
        this.$message.error(data.message)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
.el-form {
  width: 80%;
}
</style>
