<template>
    <div class="sendBack">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          送回楼层
        </div>
      </div>
      <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="small">
        <el-form-item label="送回楼层：" prop="sendFloor">
          <el-select v-model="form.sendFloor" placeholder="全部" clearable>
            <el-option v-for="item in needStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="送回时间：">
          <el-date-picker
            v-model="form.dateQuery"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
          <el-button @click="showDialogInit()" size="mini" type="success">新增信息</el-button>
        </el-form-item>
        <el-form-item class="btn-right">

        </el-form-item>
      </el-form>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="small">
          <el-table-column type="index" label="编号" width="60"></el-table-column>
          <el-table-column prop="id" label="id" width="160"></el-table-column>
          <el-table-column prop="sendFloor" label="送回楼层">
            <template slot-scope="scope">
              <span>{{ scope.row.sendFloor | needStatusFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="weight" label="重量:KG"></el-table-column>
          <el-table-column prop="sendTime" label="送回时间">
            <template slot-scope="scope">
              <span>{{ scope.row.sendTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="280" fixed="right">
            <template slot-scope="{row}">
              <el-button @click="del(row)" type="danger" size="mini" >删除</el-button>
              <el-button @click="edit(row)" type="primary" size="mini" >编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50,100,200]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

      <!--  添加弹窗-->
      <div v-if="dialogVisibleInit">
        <el-dialog
          title="添加送回信息"
          :visible.sync="dialogVisibleInit"
          width="60%"
          :before-close="handleCancelInit">
          <!--将添加与编辑功能单独封装到组件中-->
          <create  @cancel="handleCancelInit" @success="handleSuccessInit"></create>
        </el-dialog>
      </div>

      <!--  编辑弹窗-->
      <div v-if="dialogVisibleEdit">
        <el-dialog
          title="编辑送回信息"
          :visible.sync="dialogVisibleEdit"
          width="60%"
          :before-close="handleCancelEdit">
          <!--将添加与编辑功能单独封装到组件中-->
          <edit :send-back-info="sendBackInfo"  @cancel="handleCancelEdit" @success="handleSuccessEdit"></edit>
        </el-dialog>
      </div>


    </el-card>

  </div>
</template>

<script>
import {dateFormate} from '@/utils/dateUtil'
import {findAllProductStatus,findAllNeedStatus} from '@/services/status'
import {findAllBackInfoByPage,delBackInfoById} from '@/services/sendBack'
import Create from './components/Create'
import Edit from "./components/Edit";
import {needStatusToCn} from '@/utils/enums'
export default {
  name: 'schedule',
  components: { Create,Edit },
  data() {
    return {
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      sendBackInfo: {},
      dialogVisibleInit: false,
      dialogVisibleEdit: false,
      needStatusList: [],
    }
  },
  methods: {

    async loadNeedStatus() {
      const {data} = await findAllNeedStatus()
      if (200 === data.code) {
        this.needStatusList = data.data
      } else {
        this.$message.error('查询需方列表失败')
      }
    },

    // 监听子组件的添加状态，成功时触发
    handleSuccessInit () {
      // 隐藏对话框
      this.dialogVisibleInit = false
      // 刷新列表
      this.loadAllBackInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelInit () {
      // 隐藏对话框
      this.dialogVisibleInit = false
    },

    // 监听子组件的添加状态，成功时触发
    handleSuccessEdit () {
      // 隐藏对话框
      this.dialogVisibleEdit = false
      // 刷新列表
      this.loadAllBackInfoByPage()
    },
    // 监听子组件的取消状态
    handleCancelEdit () {
      // 隐藏对话框
      this.dialogVisibleEdit = false
      this.productInfo = {}
    },

    // 监听子组件的取消状态
    handleCancelTackList() {
      // 隐藏对话框
      this.dialogVisibleTackList = false
      this.productNo = null
    },

    //
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllBackInfoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllBackInfoByPage()
    },

    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      await this.loadAllBackInfoByPage()
    },

    async loadProductStatus() {
      const {data} = await findAllProductStatus()
      if (200 === data.code) {
        this.productStatusList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },


    async loadAllBackInfoByPage() {
      // 开始加载数据
      this.isLoading = true
      if (this.form && this.form.dateQuery) {
        let sendDateBegin = this.form.dateQuery[0]
        let sendDateEnd = this.form.dateQuery[1]
        this.form.sendDateBegin = sendDateBegin
        this.form.sendDateEnd = sendDateEnd
      }else {
        this.form.sendDateBegin = ""
        this.form.sendDateEnd = ""
      }
      const {data} = await findAllBackInfoByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },
    // 显示对话框
    showDialogInit() {
      this.dialogVisibleInit = true
    },

    // 显示编辑对话框
    edit(row) {
      this.sendBackInfo = row
      this.dialogVisibleEdit = true
    },


    // 删除
    async del(row) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const params = {
          id: row.id
        }
        const {data} = await delBackInfoById(params)
        if (200 === data.code) {
          this.$message.success("操作成功")
        } else {
          this.$message.warning("操作失败")
        }
        await this.loadAllBackInfoByPage()
      } catch (err) {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      }
    },

  },
  created() {
    this.loadNeedStatus()
    this.loadAllBackInfoByPage()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },

    needStatusFormate(code) {
      return needStatusToCn(code)
    },

  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

.btn-right {
  float: right;
}

.el-table {
  margin-top: 10px;
}

.btn-div {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
