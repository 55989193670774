import request from '@/utils/request'




// 分页查询送回信息
export function findAllBackInfoByPage(data) {
  return request({
    method: 'post',
    url: `/auth/sendBackInfo/findAllBackInfoByPage`,
    data
  })
}


// 根据Id删除送回信息
export function delBackInfoById(data) {
  return request({
    method: 'post',
    url: `/auth/sendBackInfo/del`,
    data
  })
}

// 保存送回信息
export function saveSendBackInfo(data) {
  return request({
    method: 'post',
    url: `/auth/sendBackInfo/saveBackInfo`,
    data
  })
}
